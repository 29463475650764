<template>
  <section class="section hero is-fullheight is-error-section white-color" id="containerFP">
    <div class="logo-cliente">
      <img id="logoCliente" src="~@/assets/logoCliente.png">
    </div>
    <div class="hero-body" id="cuerpo">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
    <div class="level-item has-text-centered logo-TLSI">
      <img id="logoTLSI" src="~@/assets/Powered_tlsi.png">
    </div>
  </section>
</template>

<script>
export default {
  name: 'FullPage',
  created () {
    this.$store.commit('fullPage', true)
    this.$store.commit('asideRightToggle', false)
  },
  beforeDestroy () {
    this.$store.commit('fullPage', false)
  }
}
</script>
<style>
  #containerFP {
    background-color: rgb(255, 255, 255);
    background-image: url("~@/assets/logo.png");
    max-height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
  }
  #logoCliente {
    max-height: 4rem;
    max-width: 13rem;
    height: fit-content;
  }
  #logoTLSI {
    max-height: 6rem;
    max-width: 10em;
    height: fit-content;
    margin-bottom: 20px;
  }
  #cuerpo {
    padding: 0rem 1.5rem;
    width: 100%;
  }
  .logo-cliente {
    text-align: right;
  }
  .background-color {
    background-color: white;
    height: 100%;
  }
</style>
