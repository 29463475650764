<template>
  <card-component title="Login" icon="lock" class="login-card" :has-card-header-background="false" :has-button-slot="true" id="loginCard">
    <form @submit.prevent="submit" method="POST">
      <b-notification type="is-danger" v-if="mensaje.ver">
        {{ mensaje.texto }}
      </b-notification>
      <b-field label="Usuario">
        <b-input name="username" v-model="form.username" required autofocus></b-input>
      </b-field>

      <b-field label="Clave">
        <b-input type="password" v-model="form.password" name="password" required password-reveal></b-input>
      </b-field>
      <hr>

      <b-field>
        <b-button type="is-dark" :class="{ 'is-loading': isLoading }" expanded @click="submit">
          Login
        </b-button>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from '@/components/template/CardComponent'

export default {
  name: 'Login',
  components: { CardComponent },
  data () {
    return {
      isLoading: false,
      form: {
        username: null,
        password: null
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      if (this.form.username) {
        this.form.username = this.form.username.toLowerCase().trim()
      }
      this.$store.dispatch('login', this.form)
        .then(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    mensaje () {
      return this.$store.getters.mensaje
    }
  }
}
</script>

<style>
  /* #loginCard {
    background-image: url("~@/assets/logo.png");
    max-height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
  } */
  #loginCard {
    /* background-color: transparent; */
    background-color: rgba(255,255,255,0.6);
  }
  /* #logoCliente {
    max-height: 8rem;
    max-width: 8rem;
    height: fit-content;
  } */
</style>
